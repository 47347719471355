import { Button } from '@mui/material'
import classNames from 'classnames'
import { FormikHelpers, useFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import validationSchema from './SetNewPassword.schema'
import messages from './SetNewPasswordForm.messages'
import styles from './SetNewPasswordForm.module.scss'
import { SetPasswordFormType } from './SetNewPasswordForm.types'
import FormattedMessage from '../FormattedMessage'
import FormTextField from '../FormTextField'

type Props = {
  classes?: {
    root?: string
  }
  onSubmit: (values: SetPasswordFormType, formik: FormikHelpers<SetPasswordFormType>) => void
}

enum FORM_FIELDS {
  NEW_PASS = 'newPassword',
  CONFIRM_NEW_PASS = 'newPasswordConfirm',
}

const SetNewPasswordForm = (props: Props) => {
  const { classes, onSubmit } = props
  const intl = useIntl()

  const fields = [FORM_FIELDS.NEW_PASS, FORM_FIELDS.CONFIRM_NEW_PASS]

  const formik = useFormik({
    validationSchema: validationSchema(intl),
    initialValues: {
      newPassword: '',
      newPasswordConfirm: '',
    },
    onSubmit,
  })

  const renderLabel = (id: FORM_FIELDS) => <FormattedMessage {...messages[id]} />

  const renderField = (name: FORM_FIELDS, label: React.ReactElement) => {
    return (
      <FormTextField
        inputProps={{ type: 'password' }}
        form={formik}
        label={label}
        field={{
          name,
          value: formik.values[name as keyof typeof formik.values],
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        key={name}
      />
    )
  }

  return (
    <form onSubmit={formik.handleSubmit} className={classNames(classes?.root, styles['form'])}>
      <>{fields.map(field => renderField(field, renderLabel(field)))}</>
      <Button variant="contained" color="primary" type="submit" disabled={formik.isSubmitting} classes={{ root: styles['form__submit'] }}>
        <FormattedMessage id="SetNewPasswordForm.SendButton" description="SetNewPasswordForm.SendButton" defaultMessage="Send" />
      </Button>
    </form>
  )
}

export default SetNewPasswordForm
