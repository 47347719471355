import ImageIcon from '@mui/icons-material/Image'
import { CircularProgress } from '@mui/material'
import classNames from 'classnames'
import React from 'react'
import Color from './LoadingSpinner.colors'
import styles from './LoadingSpinner.module.scss'
import * as Sizes from './LoadingSpinner.sizes'

type Props = {
  color?: Color
  size?: number
  margins?: boolean
  classes?: {
    root?: string
    spinner?: string
  }
  imageLoader?: boolean
  message?: React.ReactNode
  relative?: boolean
}

const defaultProps = {
  size: Sizes.NORMAL,
  color: Color.Primary,
}

const LoadingSpinner = (_props: Props) => {
  const props = { ...defaultProps, ..._props }
  const classes = classNames(styles['spinner'], props.classes?.root!, {
    [styles['spinner--margins']]: props.margins,
    [styles['spinner--image']]: props.imageLoader,
    [styles['spinner--image--relative']]: props.relative,
  })

  const loaderClasses = classNames(styles['spinner__loader--base'], {
    [styles['spinner__loader']]: props.imageLoader,
  })
  return (
    <div className={classes}>
      <div className={loaderClasses}>
        <CircularProgress
          size={props.imageLoader ? Sizes.LARGE : props.size}
          color={props.color}
          className={classNames(props.classes?.spinner!)}
          thickness={props.imageLoader ? 1.0 : 1.5}
        />
        {props.imageLoader ? <ImageIcon classes={{ root: styles['loader__image'] }} color="secondary" /> : null}
      </div>

      {props.message ? (
        <>
          <br />
          {props.message}
        </>
      ) : null}
    </div>
  )
}

export { Color, Sizes }
export default LoadingSpinner
