const palette = {}
//We can import media query breakpoints from scss
const mixins = {
  mediaQuery: {
    phone: 500,
    smallTablet: 700,
    tablet: 1024,
    laptop: 1368,
  },
  sidebar: {
    width: '85%',
    maxWidth: 380,
    toolbarSize: '48px', // Koniecznie z px!
  },
}

const overrides = {
  MuiTypography: {
    defaultProps: {
      variantMapping: {
        body1: 'span',
        body2: 'span',
        inherit: 'span',
      },
    },

    styleOverrides: {
      body1: {
        fontSize: '1rem',
        fontFamily: 'Montserrat',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        boxShadow: 'unset',
      },
      outlined: {},
      contained: {
        boxShadow: 'unset',
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        '&:before': {
          height: '0px',
        },
        '&$expanded': {
          margin: '0px',
        },
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        padding: '15px 15px',
        '&$expanded': {
          minHeight: '48px',
        },
      },
      content: {
        margin: '0px',
        '&$expanded': {
          margin: '0px',
        },
      },
    },
  },
  MuiTab: {
    root: {},
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        marginTop: '5px',
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        marginTop: '5px',
      },
    },
  },
  MuiDateCalendar: {
    styleOverrides: {
      root: {
        height: 'auto',
      },
    },
  },
}

export { palette, mixins, overrides }
