import UserInterface from '../../interfaces/UserInterface'

export enum types {
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',
}

// Sign In
export interface SignInSuccessPayload {
  user: UserInterface
}

export interface SignInSuccessAction {
  type: types.SIGN_IN_SUCCESS
  payload: SignInSuccessPayload
}

// Sign Up
export interface SignUpSuccessPayload extends SignInSuccessPayload {}

export interface SignUpSuccessAction {
  type: types.SIGN_UP_SUCCESS
  payload: SignUpSuccessPayload
}

// Log Out
export interface LogOutSuccessAction {
  type: types.LOG_OUT_SUCCESS
}

export type ActionTypes = SignInSuccessAction | SignUpSuccessAction | LogOutSuccessAction

export interface BecomeMember {
  newPassword: string
  birthday: string | number
  token: string
}
