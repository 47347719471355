import { IntlShape } from 'react-intl'
import validationMessages from '../../app/i18n/validationMessages'
import Yup from '../../utils/yup'

const schema = (intl: IntlShape) => {
  return Yup.object().shape({
    email: Yup.string()
      .ensure()
      .required(intl?.formatMessage(validationMessages.schemaFieldRequired))
      .wfEmail(intl?.formatMessage(validationMessages.schemaWrongEmail))
      .trim(),
  })
}
export default schema
