import { SelectChangeEvent } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import React, { ReactNode } from 'react'
import Flag from 'react-country-flag'
import styles from './ChannelSelect.module.scss'
import { ChannelBriefInterface, ChannelSiteInterface } from '../../../interfaces/ChannelInterface'
import SwitcherBase from '../SwitcherBase'
import { SwitcherBaseItemType } from '../SwitcherBase/SwitcherBase.types'

type Props = {
  selectedChannel?: number | null
  name: string
  label?: string | ReactNode
  onSelected: (event: SelectChangeEvent<any>) => void
  classes?: {
    root?: string
  }
  disabled?: boolean
  items: ChannelSiteInterface[] | ChannelBriefInterface[]
  extraItems?: ChannelSiteInterface[] | ChannelBriefInterface[]
}

const ChannelSelect = (props: Props) => {
  const renderDetails = (item: SwitcherBaseItemType) => {
    if (!('currency' in item)) {
      return <></>
    }
    return (
      <div className={styles['box']}>
        <span>{item.currency}</span>
        <Flag countryCode={item.flag.toLocaleLowerCase()} svg className={styles['flag__icon']} alt="Country Flag" />
      </div>
    )
  }

  const onSelected = (event: SelectChangeEvent<any>) => {
    props.onSelected(event)
  }

  return (
    <SwitcherBase
      classes={{
        root: classNames(styles['location'], props.classes?.root!),
      }}
      onSelected={onSelected}
      items={props.items}
      value={props.selectedChannel || ''}
      itemValueKey="channelId"
      itemNameKey="name"
      name={props.name}
      label={props.label}
      renderExtraData={renderDetails}
      disabled={props.disabled}
      hiddenItems={props.extraItems}
    />
  )
}

export default ChannelSelect
