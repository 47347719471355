import { Button, Dialog, DialogActions, DialogContent, DialogTitle, SelectChangeEvent } from '@mui/material'
import _ from 'lodash'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import styles from './LocationSwitcher.module.scss'
import ChannelContext from '../../../context/ChannelContext'
import { ChannelSiteInterface, FlowType, Type as ChannelType } from '../../../interfaces/ChannelInterface'
import { State } from '../../../services/reducers'
import FormattedMessage from '../../FormattedMessage'
import ChannelSelect from '../ChannelSelect/ChannelSelect'

type Props = {
  onChange?: () => void
  classes?: {
    root?: string
  }
}

const LocationSwitcher = (props: Props) => {
  const { classes } = props
  const [targetSite, setTargetSite] = React.useState<ChannelSiteInterface | null>(null)
  const channel = useContext(ChannelContext)
  const allChannelSites = useSelector((state: State) => state.global.channelSites)
  let channelSites = allChannelSites.filter(
    i => (channel.current?.type === ChannelType.Opposite ? i.alt == true : i.alt !== true) && i.flowType === FlowType.Customer,
  )

  //TODO: remove this after backend changes  WF-3930
  if (channelSites.length === 0) {
    channelSites = allChannelSites
  }
  const site = channelSites.find(i => i.current === true)

  const onChange = (event: SelectChangeEvent<any>) => {
    const currentSite = channelSites.find(i => i.current === true) || null
    const channelId = event.target.value as number

    if (channelId === currentSite?.channelId) {
      setTargetSite(null)
    } else {
      setTargetSite(channelSites.find(i => i.channelId === channelId) || currentSite)
    }

    props.onChange ? props.onChange() : _.noop()
  }

  const onClose = () => {
    setTargetSite(null)
  }

  const onProceed = () => {
    if (targetSite !== null) {
      window.location.href = targetSite.url
    } else {
      onClose()
    }
  }

  return (
    <>
      <ChannelSelect
        classes={{
          root: classes?.root,
        }}
        name="location-switcher"
        label={<FormattedMessage id="LocationSwitcher.Label" description="LocationSwitcher.Label" defaultMessage="Location" />}
        onSelected={onChange}
        items={channelSites}
        selectedChannel={site?.channelId}
      />
      <Dialog open={targetSite !== null} onClose={onClose} disableEscapeKeyDown fullWidth>
        <DialogTitle>
          <FormattedMessage
            className={styles['confirmation__heading']}
            id="LocationSwitcher.ConfirmationHeading"
            defaultMessage="Do you want to leave this site?"
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="LocationSwitcher.ExplainHeading"
            defaultMessage="You can switch to another site to find another products that may not be available here, however please note:"
            className={styles['explain__heading']}
          />
          <FormattedMessage
            className={styles['explain__item']}
            id="LocationSwitcher.ExplainUrl"
            defaultMessage="You will proceed to {link}"
            values={{
              link: targetSite?.url,
            }}
          />
          <FormattedMessage
            className={styles['explain__item']}
            id="LocationSwitcher.ExplainCart"
            defaultMessage="You will lose any products in your Bag when you switch"
          />
          <FormattedMessage
            className={styles['explain__item']}
            id="LocationSwitcher.ExplainShippingPrice"
            defaultMessage="Shipping pricing may be higher"
          />
          <FormattedMessage
            className={styles['explain__item']}
            id="LocationSwitcher.ExplainShipping"
            defaultMessage="Shipping may not be available to your address"
          />
          <FormattedMessage
            className={styles['explain__item']}
            id="LocationSwitcher.ExplainLogout"
            defaultMessage="You will be logged out of your account"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            <FormattedMessage id="LocationSwitcher.StayHere" defaultMessage="Stay here" />
          </Button>
          <Button onClick={onProceed} color="info" variant="contained" classes={{ root: styles['none'] }}>
            <FormattedMessage
              id="LocationSwitcher.ProceedThere"
              defaultMessage="Proceed to {link} shop"
              values={{
                link: targetSite?.name,
              }}
            />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default LocationSwitcher
