import { ListItemIcon, MenuItem, SelectChangeEvent } from '@mui/material'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import styles from './SwitcherBase.module.scss'
import { SwitcherBaseItemType } from './SwitcherBase.types'
import SelectField from '../../SelectField'
import TextField from '../../TextField'

type Props = {
  classes?: {
    root?: string
    formControl?: string
    label?: string
    select?: string
  }
  name: string
  label: React.ReactNode
  itemValueKey: string
  itemNameKey: ((item: SwitcherBaseItemType) => string) | string
  onSelected: (event: SelectChangeEvent<any>) => void
  items: SwitcherBaseItemType[]
  hiddenItems?: SwitcherBaseItemType[]
  value: string | number
  readOnly?: boolean
  disabled?: boolean
  renderExtraData?: (item: SwitcherBaseItemType) => JSX.Element
}

const SwitcherBase = (props: Props) => {
  const { classes, name, label, onSelected, items, hiddenItems, itemValueKey, itemNameKey, value, readOnly, disabled, renderExtraData } =
    props

  const rootClasses = classes?.root
  const formControlClasses = { root: classNames(styles['form-control'], classes?.root) }

  const renderOption = (item: SwitcherBaseItemType, hidden: boolean = false) => (
    <MenuItem
      disabled={_.get(item, itemValueKey) == value}
      key={`${name}-${_.get(item, itemValueKey)}`}
      value={_.get(item, itemValueKey)}
      selected={_.get(item, itemValueKey) == value}
      classes={{ root: hidden ? styles['item--hidden'] : styles['item'] }}
    >
      <>
        <div>{_.isFunction(itemNameKey) ? itemNameKey(item) : _.get(item, itemNameKey)}</div>
        {renderExtraData ? renderExtraData(item) : _.noop()}
      </>
    </MenuItem>
  )

  const options = items.map(item => renderOption(item))
  const hiddenOptions = hiddenItems?.map(item => renderOption(item, true))

  if (readOnly) {
    return (
      <TextField
        fullWidth
        name={name}
        value={value || ''}
        classes={{ root: classNames(styles['text-field']) }}
        formControlProps={{
          classes: formControlClasses,
        }}
        inputProps={{
          readOnly: true,
        }}
        label={label}
        disabled
      />
    )
  }

  return (
    <form autoComplete="off" className={rootClasses}>
      <SelectField
        fullWidth
        name={name}
        value={value || ''}
        onChange={(event: SelectChangeEvent<any>) => {
          onSelected(event)
        }}
        MenuProps={{
          container: () => document.querySelector('#app > .themed'),
        }}
        classes={{ select: classNames(styles['select'], classes?.select) }}
        formControlProps={{
          classes: formControlClasses,
        }}
        label={label}
        readOnly={readOnly}
        disabled={disabled}
      >
        {options}
        {hiddenOptions}
      </SelectField>
    </form>
  )
}

export default SwitcherBase
