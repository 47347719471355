import HelpIcon from '@mui/icons-material/HelpOutline'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import FormControl from '@mui/material/FormControl'
import Tooltip, { TooltipProps } from '@mui/material/Tooltip'
import classNames from 'classnames'
import { FormikProps } from 'formik'
import _ from 'lodash'
import React, { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import styles from './SignUpFormView.module.scss'
import globalMessages from '../../../app/i18n/definedMessages'
import { NewsletterOptionInterface } from '../../../interfaces/NewsletterInterface'
import CheckboxField from '../../CheckboxField'
import FormattedMessage from '../../FormattedMessage'
import FormCheckboxField from '../../FormCheckboxField'
import FormDateField from '../../FormDateField'
import FormTextField from '../../FormTextField'
import { FormikValues } from '../SignUpForm'

type Props = {
  formik: FormikProps<FormikValues>
  isCheckout: boolean
  buttonLabel: ReactNode | string
  handleFormKindCheckbox: () => void
  guestMode: boolean
  languageIsoCode: string
  expanded: boolean
  exited: boolean
  onExpand: () => void
  newsletterOption: NewsletterOptionInterface | null
  isMobile: boolean
}

const SignUpFormView = (props: Props) => {
  const {
    formik,
    isCheckout,
    buttonLabel,
    handleFormKindCheckbox,
    guestMode,
    languageIsoCode,
    onExpand,
    newsletterOption,
    isMobile,
    expanded,
    exited,
  } = props

  const [newsletterHintOpen, setNewsletterHintOpen] = React.useState<boolean>(false)

  const intl = useIntl()

  const toggleClassName = classNames({
    [styles['form__element--hidding']]: !expanded,
    [styles['form__element--hidden']]: exited,
  })

  const reverseToggleClassName = classNames({
    [styles['form__element--hidding']]: expanded,
    [styles['form__element--hidden']]: !exited,
  })

  const formControlProps = {
    className: classNames(toggleClassName),
    fullWidth: true,
  }

  const newsletterPlacement: TooltipProps['placement'] = 'top'

  const renderField = (name: string, label: React.ReactElement, type: string = 'text', disablePaste: boolean = false) => {
    return (
      <FormTextField
        inputProps={{ type }}
        form={formik}
        label={label}
        field={{
          name,
          value: formik.values[name as keyof FormikValues],
          onChange: formik.handleChange,
          onBlur: formik.handleBlur,
        }}
        formControlProps={formControlProps}
        disablePaste={disablePaste}
      />
    )
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {renderField(
          'email',
          <FormattedMessage id="SignUpForm.EmailAddress" description="SignUpForm.EmailAddress" defaultMessage="E-mail address" />,
        )}
        {renderField(
          'repeatEmail',
          <FormattedMessage
            id="SignUpForm.RepeateEmailAddress"
            description="SignUpForm.RepeateEmailAddress"
            defaultMessage="Repeat email"
          />,
          'text',
          true,
        )}
        {renderField(
          'firstName',
          <FormattedMessage id="SignUpForm.FirstName" description="SignUpForm.FirstName" defaultMessage="Your first name" />,
        )}
        {renderField(
          'lastName',
          <FormattedMessage id="SignUpForm.LastName" description="SignUpForm.LastName" defaultMessage="Your last name" />,
        )}
        {isCheckout && (
          <CheckboxField
            name="createFTF"
            label={
              <FormattedMessage
                id="SignUpForm.CreateFeedTheFunkys"
                description="SignUpForm.CreateFeedTheFunkys"
                defaultMessage="Create a Feed The Funkys account"
              />
            }
            value={!guestMode}
            formControlProps={formControlProps}
            onChange={handleFormKindCheckbox}
          />
        )}
        {!guestMode && (
          <>
            {renderField(
              'plainPassword',
              <FormattedMessage id="SignUpForm.YourPassword" description="SignUpForm.YourPassword" defaultMessage="Your password" />,
              'password',
            )}
            {renderField(
              'newPassword',
              <FormattedMessage id="SignUpForm.RepeatPassword" description="SignUpForm.RepeatPassword" defaultMessage="Repeat password" />,
              'password',
            )}
            <FormDateField
              field={{
                name: 'birthday',
                value: formik.values.birthday,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
              }}
              form={formik}
              label={intl.formatMessage({
                id: 'SignUpForm.DateOfBirth',
                description: 'SignUpForm.DateOfBirth',
                defaultMessage: 'Date of birth',
              })}
              languageIsoCode={languageIsoCode}
              formControlProps={formControlProps}
              pickerProps={{
                autoOk: true,
                openTo: 'year',
              }}
            />
          </>
        )}
        <FormCheckboxField
          field={{
            name: 'tos',
            value: formik.values.tos,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
          }}
          label={
            <FormattedMessage
              id="SignUpForm.AcceptTermsOfService"
              description="SignUpForm.AcceptTermsOfService"
              defaultMessage="I accept the Terms Of Service"
            />
          }
          form={formik}
          formControlProps={formControlProps}
        />
        {newsletterOption != null && (
          <div className={classNames(styles['form__field__tooltip-box'], toggleClassName)}>
            <FormCheckboxField
              field={{
                name: 'newsletter',
                value: formik.values.newsletter,
                onChange: formik.handleChange,
                onBlur: formik.handleBlur,
              }}
              label={
                <>
                  <FormattedMessage
                    id="SignUpForm.SignToOurNewsletter"
                    description="SignUpForm.SignToOurNewsletter"
                    defaultMessage="Sign up to our newsletter"
                  />
                </>
              }
              form={formik}
              formControlProps={_.omit(formControlProps, 'fullWidth')}
            />
            <ClickAwayListener
              onClickAway={() => {
                setNewsletterHintOpen(false)
              }}
            >
              <Tooltip
                enterTouchDelay={400}
                className={styles['form__field__tooltip']}
                placement={newsletterPlacement}
                title={<FormattedMessage {...globalMessages.appNewsletterSpamHint} />}
                open={newsletterHintOpen}
                onOpen={() => {}}
              >
                <span style={{ marginLeft: 5 }}>
                  <HelpIcon
                    style={{ fontSize: 18 }}
                    onClick={() => {
                      setNewsletterHintOpen(true)
                    }}
                    onMouseOver={() => {
                      setNewsletterHintOpen(true)
                    }}
                    onMouseLeave={() => {
                      setNewsletterHintOpen(false)
                    }}
                  />
                </span>
              </Tooltip>
            </ClickAwayListener>
          </div>
        )}

        <FormControl fullWidth>
          <Button variant="contained" color="primary" type="submit" className={toggleClassName} disabled={formik.isSubmitting}>
            {buttonLabel}
          </Button>
          <Button variant="contained" color="primary" onClick={onExpand} className={reverseToggleClassName}>
            {buttonLabel}
          </Button>
        </FormControl>
      </form>
    </div>
  )
}

export default SignUpFormView
