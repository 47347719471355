import _ from 'lodash'
import { BecomeMember } from './auth.types'
import { UserNewsletterOptionInterface } from '../../interfaces/UserNewsletterOptionInterface'
import axios from '../../utils/axios'
import DateUtils from '../../utils/date'

interface SignUpObject extends GuestObject {
  birthday: string | number
  plainPassword: string
  newPassword: string | object
}

interface GuestObject {
  firstName: string
  lastName: string
  email: string
  tos: boolean
  newsletter: boolean
  newsletterOptions?: UserNewsletterOptionInterface[]
}

export function signUp(formData: SignUpObject) {
  const data = _.cloneDeep(formData)
  data.birthday = DateUtils.birthdayToUnixTimestamp(formData.birthday)
  data.newPassword = { second: formData.plainPassword, first: formData.newPassword }
  return axios({
    method: 'POST',
    url: '/profile.json',
    data: formData,
  })
}

export function createGuest(formData: GuestObject) {
  return axios({
    method: 'POST',
    url: '/profile/guest.json',
    data: formData,
  })
}

export function signIn(email: string, password: string) {
  return axios({
    method: 'POST',
    url: '/security/login',
    headers: { 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' },
    data: {
      username: email,
      password,
    },
  })
}
//TODO: remove?
export function setCookie(tokenId: string, secret: string) {
  return axios({
    method: 'GET',
    url: `/security/set-cookie/${tokenId}/${secret}/1`,
  })
}
//TODO: remove?
export function destroyCookie() {
  return axios({
    method: 'GET',
    url: '/security/destroy-cookie',
  })
}

export function logOut() {
  return axios({
    method: 'GET',
    url: '/security/logout',
  })
}

export function isAuth() {
  return axios({
    method: 'GET',
    url: '/security/is-auth',
  })
}

export function facebookAuth() {
  return axios({
    method: 'POST',
    url: '/security/login/facebook.json',
  })
}

export function becomeMember(formData: BecomeMember) {
  const data = _.cloneDeep(formData)
  data.birthday = DateUtils.birthdayToUnixTimestamp(formData.birthday)
  return axios({
    method: 'POST',
    url: '/security/password/became-member-set.json',
    data,
  })
}
