import { Button, FormControl } from '@mui/material'
import { Field, Form, Formik, FormikHelpers } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useIntl } from 'react-intl'
import schema from './ResetPasswordFormSchema'
import { RESET_PASSWORD_ORIGIN } from '../../web/views/ResetPassword'
import FormattedMessage from '../FormattedMessage'
import FormTextField from '../FormTextField'

export interface FormikValues {
  email: string
  from: RESET_PASSWORD_ORIGIN
}

interface Props {
  onSubmit: (values: FormikValues, formik: FormikHelpers<FormikValues>) => void
  className: string
}

const ResetPasswordForm: React.FC<Props> = (props: Props) => {
  const intl = useIntl()

  return (
    <div className={props.className}>
      <Formik
        initialValues={{
          email: '',
          from: RESET_PASSWORD_ORIGIN.STANDALONE,
        }}
        validationSchema={schema(intl)}
        onSubmit={(values: FormikValues, formik: FormikHelpers<FormikValues>) => {
          props.onSubmit(values, formik)
        }}
        render={({ submitForm, isSubmitting }) => (
          <Form>
            <Field
              name="email"
              label={intl.formatMessage({ id: 'ResetPasswordForm.EmailAddress', defaultMessage: 'E-mail address' })}
              component={FormTextField}
              formControlProps={{ fullWidth: true }}
            />
            <FormControl fullWidth>
              <Button variant="contained" color="primary" fullWidth onClick={submitForm} disabled={isSubmitting}>
                <FormattedMessage
                  id="ResetPasswordForm.ResetActionButton"
                  description="ResetPasswordForm.ResetActionButton"
                  defaultMessage="Reset my password"
                />
              </Button>
            </FormControl>
          </Form>
        )}
      />
    </div>
  )
}

export default ResetPasswordForm
