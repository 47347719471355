import { IntlShape } from 'react-intl'
import validationMessages from '../../app/i18n/validationMessages'
import * as schemaConst from '../../utils/validationConst'
import Yup from '../../utils/yup'

const schema = (intl: IntlShape) =>
  Yup.object().shape({
    email: Yup.string()
      .ensure()
      .required(intl.formatMessage(validationMessages.schemaFieldRequired))
      .wfEmail(intl.formatMessage(validationMessages.schemaWrongEmail))
      .max(schemaConst.emailMaxChars, intl.formatMessage(validationMessages.schemaMaxChar, { val: schemaConst.emailMaxChars }))
      .trim(),
    repeatEmail: Yup.string()
      .required(intl.formatMessage(validationMessages.schemaFieldRequired))
      .oneOf([Yup.ref('email')], intl.formatMessage(validationMessages.schemaEmailsNotMatch)),
    firstName: Yup.string()
      .ensure()
      .required(intl.formatMessage(validationMessages.schemaFieldRequired))
      .max(schemaConst.firstNameMaxChars, intl.formatMessage(validationMessages.schemaMaxChar, { val: schemaConst.firstNameMaxChars }))
      .trim(),
    lastName: Yup.string()
      .ensure()
      .required(intl.formatMessage(validationMessages.schemaFieldRequired))
      .max(schemaConst.lastNameMaxChars, intl.formatMessage(validationMessages.schemaMaxChar, { val: schemaConst.lastNameMaxChars }))
      .trim(),
    tos: Yup.boolean()
      .required(intl.formatMessage(validationMessages.schemaFieldRequired))
      .oneOf([true], intl.formatMessage(validationMessages.schemaTermsNotAccepted)),
  })

export default schema
